import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { TailSpin } from "react-loader-spinner";
import "./receiptForm.css";
import NavBar from "../../components/navBar/navBar.js";

function ReceiptForm() {
    const [formData, setFormData] = useState({
        name: "",
        startLocation: "",
        destination: "",
        rideAmount: "",
        vatPercentage: 10,
        email: "",
        paymentMethod: "card",
        date: "",
        time: "",
    });

    const [emailJSConfig, setEmailJSConfig] = useState({
        serviceID: "",
        templateID: "",
        userID: ""
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Fetch EmailJS secrets from server
        const fetchSecrets = async () => {
            try {
                const response = await fetch("https://vipkyyti-api.onrender.com/");
                const data = await response.json();
                setEmailJSConfig({
                    serviceID: data.emailServiceId,
                    templateID: data.receiptTemplateId,
                    userID: data.emailUserId,
                });
            } catch (error) {
                console.error("Error fetching secrets:", error);
            }
        };

        fetchSecrets();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === "rideAmount") {
            if (value === "" || /^[0-9]*[.,]?[0-9]*$/.test(value)) {
                setFormData({ ...formData, [name]: value });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const convertToDecimal = (value) => {
        return parseFloat(value.replace(",", "."));
    };

    const calculateBasePrice = () => {
        const totalAmount = convertToDecimal(formData.rideAmount);
        const basePrice = totalAmount / (1 + formData.vatPercentage / 100);
        return basePrice.toFixed(2);
    };

    const calculateVAT = () => {
        const totalAmount = convertToDecimal(formData.rideAmount);
        const basePrice = parseFloat(calculateBasePrice());
        const vatAmount = totalAmount - basePrice;
        return vatAmount.toFixed(2);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        setLoading(true);

        const basePrice = calculateBasePrice();
        const vatAmount = calculateVAT();

        const formDataWithCalculations = {
            name: formData.name,
            startLocation: formData.startLocation,
            destination: formData.destination,
            rideAmount: formData.rideAmount,
            vatPercentage: formData.vatPercentage,
            vatAmount: vatAmount,
            basePrice: basePrice,
            totalAmount: formData.rideAmount,
            email: formData.email,
            paymentMethod: formData.paymentMethod,
            date: formData.date,
            time: formData.time,
        };

        // Send the email using fetched EmailJS secrets without PDF attachment
        emailjs
            .send(
                emailJSConfig.serviceID,
                emailJSConfig.templateID,
                formDataWithCalculations,
                emailJSConfig.userID
            )
            .then(
                (response) => {
                    console.log("SUCCESS!", response.status, response.text);
                    alert("Email sent successfully!");
                    setLoading(false);
                },
                (error) => {
                    console.log("FAILED...", error);
                    alert("Failed to send email.");
                    setLoading(false);
                }
            );
    };

    return (
        <div className="receiptFormBody">
            <NavBar />
            <form className="receiptForm" onSubmit={sendEmail}>
                <div>
                    <label>Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Start Location:</label>
                    <input
                        type="text"
                        name="startLocation"
                        value={formData.startLocation}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Destination:</label>
                    <input
                        type="text"
                        name="destination"
                        value={formData.destination}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Total Ride Amount (€):</label>
                    <input
                        type="text"
                        name="rideAmount"
                        value={formData.rideAmount}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>VAT Percentage (%):</label>
                    <input
                        type="number"
                        name="vatPercentage"
                        value={formData.vatPercentage}
                        onChange={handleChange}
                        required
                        min="0"
                    />
                </div>
                <div>
                    <label>Email of Customer:</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Date:</label>
                    <input
                        type="date"
                        name="date"
                        value={formData.date}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Time:</label>
                    <input
                        type="time"
                        name="time"
                        value={formData.time}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Payment Method:</label>
                    <div>
                        <label>
                            <input
                                type="radio"
                                name="paymentMethod"
                                value="card"
                                checked={formData.paymentMethod === "card"}
                                onChange={handleChange}
                            />
                            Card
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="paymentMethod"
                                value="cash"
                                checked={formData.paymentMethod === "cash"}
                                onChange={handleChange}
                            />
                            Cash
                        </label>
                    </div>
                </div>
                <button type="submit" disabled={loading}>
                    {loading ? "Sending..." : "Send Receipt"}
                </button>

                {loading && (
                    <div className="loaderOverlay">
                        <div className="loaderContainer">
                            <TailSpin
                                height="80"
                                width="80"
                                color="#3498db"
                                ariaLabel="loading"
                            />
                            <p>Sending rceipt...</p>
                        </div>
                    </div>
                )}
            </form>
        </div>
    );
}

export default ReceiptForm;
