import React from 'react';
import NavBar from "../../components/navBar/navBar.js";
import './underConstruction.css';

const UnderConstruction = () => {
    return (
        <div>
            <NavBar />
            <div className="pageContainer">
                <div className="card">
                    <h1 className="title">Tervetuloa VIPkyyti.fi sivuille!</h1>
                    <p className="description">
                        Olemme uuden sukupolven taksipalveluita tarjoava yritys pääkaupunkiseudulla rautaisella ammattitaidolla ja laadukkaalla palvelulla.
                    </p>
                    <p className="text">
                        Tiedustelut, tilaukset ja kyselyt sähköpostilla, tekstiviestillä tai WhatsAppilla (kiireiden vuoksi emme ehdi vastaamaan puheluihin).
                    </p>
                    <div className="contactInfo">
                        <p><strong>Sähköposti:</strong> info@vipkyyti.fi</p>
                        <p><strong>Viestit:</strong> +358406797706</p>
                    </div>
                    <p className="subText">
                        Ennakkotilaukset - sopimusajot - lentokenttänoudot
                    </p>
                    <p className="subText">
                        Viralliset sivut aukeavat keväällä 2025.
                    </p>
                    <p className="signature">
                        Kuljetukset J Turunen
                    </p>
                </div>
            </div>
        </div>
    );
};

export default UnderConstruction;
