import React, { useState } from 'react';
import './navBar.css';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../authContext/authContext.js';

const NavBar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { currentUser, logout } = useAuth();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLogout = async () => {
        try {
            await logout();
        } catch (error) {
            console.error("Failed to log out:", error);
        }
    };

    return (
        <nav className='navbar'>
            <div className='logo'>
                <h1>VIPKyyti</h1>
            </div>
            <div className='menu-toggle' onClick={toggleMenu}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <ul className={menuOpen ? 'active' : ''}>
                <li>
                    <NavLink
                        to="/"
                        end
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        Home
                    </NavLink>
                </li>
                {/* <li>
                    <NavLink
                        to="/riderequestform"
                        className={({ isActive }) => (isActive ? 'active' : '')}
                    >
                        Ride Request
                    </NavLink>
                </li> */}
                {currentUser && (
                    <li>
                        <NavLink
                            to="/receiptform"
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            Invoice Maker
                        </NavLink>
                    </li>
                )}
                {!currentUser ? (
                    <li>
                        <NavLink
                            to="/login"
                            className={({ isActive }) => (isActive ? 'active' : '')}
                        >
                            Login
                        </NavLink>
                    </li>
                ) : (
                    <li>
                        <button onClick={handleLogout} className="logout-button">
                            Logout
                        </button>
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default NavBar;
