// import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/navBar/navBar.js';
// import Home from './views/home/home.js';
import ReceiptForm from './views/receiptForm/receiptForm.js';
// import RideRequestForm from './views/rideRequestForm/rideRequestForm.js';
// import Signup from './views/signUp/signUp.js';
import Login from './views/logIn/logIn.js';
import { AuthProvider } from './components/authContext/authContext.js';
import PrivateRoute from './components/privateRoute/privateRoute.js';
import UnderConstruction from './views/underConstruction/underConstruction.js';

function App() {
  return (
    <AuthProvider>
      <Router>
        {/* <NavBar /> */}
        <Routes>
          <Route path="/" element={<UnderConstruction />} />
          <Route path="/receiptform" element={<PrivateRoute><ReceiptForm /></PrivateRoute>} />
          {/* <Route path="/riderequestform" element={<RideRequestForm />} /> */}
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<div><NavBar /><p>This page does not exist</p></div>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
